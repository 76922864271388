import { CalculateOfferPayload } from '../../shared/interfaces/calculate-offer-payload.interface';
import { CreationLockPayload } from '../../shared/interfaces/creation-lock-payload.interface';
import { ConfirmSignaturePayload } from '../../../../interfaces/idefend/confirm-signature-payload.interface';

export class CalculateOffer {
  static readonly type = '[iDefend API] Calculate Offer';

  constructor(public payload: CalculateOfferPayload) {}
}

export class LockOffer {
  static readonly type = '[iDefend API] Lock Offer';

  constructor(public payload: CreationLockPayload) {}
}

export class SignOffer {
  static readonly type = '[iDefend API] Sign Offer v2';

  constructor(
    public id: number,
    public payload: ConfirmSignaturePayload,
  ) {}
}

export class GetPolicyByNumber {
  static readonly type = '[iDefend API] Get Policy By Number';

  constructor(public policyNo: string) {}
}

export class GetPolicy {
  static readonly type = '[iDefend API] Get Policy';

  constructor(public id: number) {}
}

export class ResetOfferState {
  static readonly type = '[UI] Reset Offer State';

  constructor() {}
}

export class ResetDiscountCode {
  static readonly type = '[UI] Reset Discount Code';

  constructor() {}
}

export class ApplyDiscountCode {
  static readonly type = '[UI] Apply Discount Code';

  constructor(
    public code: string,
    public amount: string,
  ) {}
}

export class ApplyAffiliationDiscount {
  static readonly type = '[UI] Apply Affiliation Discount';

  constructor(
    public link: string,
    public amount: string,
  ) {}
}
