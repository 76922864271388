import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { take, tap } from 'rxjs/operators';
import { CalculateOfferResponse } from '../../shared/interfaces/calculate-offer-response.interface';
import { CreationLockResponse } from '../../shared/interfaces/creation-lock-response.interface';
import { assignNewDiscountAmount } from '../helpers/assign-new-discount-amount';
import { PolicyCreatorService } from '../policy-creator.service';
import {
  ApplyAffiliationDiscount,
  ApplyDiscountCode,
  CalculateOffer,
  GetPolicy,
  GetPolicyByNumber,
  LockOffer,
  ResetDiscountCode,
  ResetOfferState,
  SignOffer,
} from './offer.action';
import { defaults, OfferStateModel } from './offer.model';

@State<OfferStateModel>({
  name: 'offerState',
  defaults,
})
@Injectable()
export class OfferState {
  constructor(private policyCreatorService: PolicyCreatorService) {}

  @Selector()
  static offer({ offer }: OfferStateModel): CalculateOfferResponse {
    return offer;
  }

  @Selector()
  static offerLocked({ offerLocked }: OfferStateModel): CreationLockResponse {
    return offerLocked;
  }

  @Action(CalculateOffer, { cancelUncompleted: true })
  public calculateOffer(
    { getState, patchState }: StateContext<OfferStateModel>,
    { payload }: CalculateOffer,
  ) {
    const offerState = getState().offer;

    return this.policyCreatorService.getOffer(payload).pipe(
      tap({
        next: (offer) => {
          patchState({
            offer: {
              ...offer,
              discountCode: offerState?.discountCode,
              discountAmount: offerState?.discountAmount,
              discountLink: offerState?.discountLink,
            },
          });
        },
        error: () => {
          patchState({ offer: null });
        },
      }),
    );
  }

  @Action(SignOffer)
  public signOffer(
    ctx: StateContext<OfferStateModel>,
    { id, payload }: SignOffer,
  ) {
    const state = ctx.getState();
    const offerLockedState = state.offerLocked;
    return this.policyCreatorService.signOffer(id, payload).pipe(
      tap({
        next: () => {
          ctx.setState({
            ...state,
            ...offerLockedState,
          });
        },
      }),
    );
  }

  @Action(LockOffer)
  public lockOffer(ctx: StateContext<OfferStateModel>, { payload }: LockOffer) {
    return this.policyCreatorService.lockOffer(payload).pipe(
      take(1),
      tap({
        next: (offer) => {
          ctx.patchState({ offerLocked: offer });
          return offer;
        },
        error: () => {
          ctx.patchState({ offerLocked: null });
        },
      }),
    );
  }

  @Action(GetPolicyByNumber)
  public getPolicyByNumber(
    ctx: StateContext<OfferStateModel>,
    { policyNo }: GetPolicyByNumber,
  ) {
    return this.policyCreatorService.getPolicyByNumber(policyNo).pipe(
      take(1),
      tap({
        next: (res) => {
          if (res && res.length > 0) {
            ctx.patchState({
              offerLocked: {
                ...res[0],
              },
            });
          }
        },
      }),
    );
  }

  @Action(GetPolicy)
  public getPolicy(ctx: StateContext<OfferStateModel>, { id }: GetPolicy) {
    return this.policyCreatorService.getPolicy(id).pipe(
      take(1),
      tap({
        next: (policy) => {
          ctx.patchState({
            offerLocked: {
              ...policy,
            },
          });
        },
        error: (e) => {
          console.log(e);
        },
      }),
    );
  }

  @Action(ResetOfferState)
  public resetState({ setState }: StateContext<OfferStateModel>) {
    return setState(defaults);
  }

  @Action(ResetDiscountCode)
  public resetDiscountCode({
    getState,
    patchState,
  }: StateContext<OfferStateModel>) {
    const offer = getState().offer;

    if (offer) {
      offer.discountCode = null;
      offer.discountAmount = null;
      offer.premiumDiscounted = null;
    }

    patchState({
      offer,
    });
  }

  @Action(ApplyDiscountCode)
  public applyDiscountCode(
    { getState, patchState }: StateContext<OfferStateModel>,
    { code, amount }: ApplyDiscountCode,
  ) {
    const offer = getState().offer;
    if (offer) {
      offer.discountCode = code;
      offer.discountAmount =
        Number(amount) > Number(offer.discountAmount)
          ? amount
          : offer.discountAmount;
      const { premiumMin, premiumSuggested, discountAmount } = offer;

      if (!amount) {
        offer.discountAmount = null;
        offer.premiumDiscounted = null;
      }

      if (premiumSuggested && !!amount) {
        const discountValue =
          premiumSuggested - (premiumSuggested * Number(discountAmount)) / 100;
        if (discountValue >= premiumMin) {
          offer.premiumDiscounted = discountValue;
        }
      }
    }

    patchState({
      offer,
    });
  }

  @Action(ApplyAffiliationDiscount)
  public applyAffiliationDiscount(
    { getState, patchState }: StateContext<OfferStateModel>,
    { link, amount }: ApplyAffiliationDiscount,
  ) {
    const offerOrigin = getState().offer;
    const offer = {
      ...offerOrigin,
      discountLink: link,
      discountAmount: assignNewDiscountAmount(amount, offerOrigin),
    };

    if (!amount) {
      offer.discountAmount = null;
      offer.premiumDiscounted = null;
    }

    if (offer.premiumSuggested && !!amount) {
      const discountValue =
        offer.premiumSuggested -
        (offer.premiumSuggested * Number(offer.discountAmount)) / 100;
      if (discountValue >= offer.premiumMin) {
        offer.premiumDiscounted = discountValue;
      }
    }

    patchState({
      offer,
    });
  }
}
