import * as moment from 'moment';
import { CalculateOfferResponse } from '../../shared/interfaces/calculate-offer-response.interface';
import {
  CreationLockPayload,
  Owner,
} from '../../shared/interfaces/creation-lock-payload.interface';
import { PolicyCreatorStateModel } from '../store/policy-creator.model';
import { calculatePremiumForAnnual } from './calculate-premium-for-annual';
import { calculatePremiumForInstalments } from './calculate-premium-for-instalments';
import { checkForInstalments } from './check-for-instalments';

export function mapOfferLockPayload(
  policyCreatorState: PolicyCreatorStateModel,
  offer: CalculateOfferResponse,
): CreationLockPayload {
  let owner: Owner;
  const insurer = policyCreatorState.thirdStepPayload.insurer;
  let insured = policyCreatorState.thirdStepPayload.insured;

  if (insured.sameAsInsurer) {
    insured = insurer;
  }

  if (policyCreatorState.thirdStepPayload.insured.sameAsInsurer) {
    owner = {
      contact: {
        type:
          policyCreatorState.thirdStepPayload.insurer.clientType ===
          'individual'
            ? 'person'
            : 'company',
        address: {
          addressLine1: policyCreatorState.thirdStepPayload.insurer.address1,
          addressLine2:
            policyCreatorState.thirdStepPayload.insurer.address2.length === 0
              ? ' '
              : policyCreatorState.thirdStepPayload.insurer.address2,
          street: policyCreatorState.thirdStepPayload.insurer.address1,
          city: policyCreatorState.thirdStepPayload.insurer.city,
          postCode: policyCreatorState.thirdStepPayload.insurer.postalCode,
          countryCode: 'PL',
        },
        identificationNumber:
          policyCreatorState.thirdStepPayload.insurer.idNumber,
      },
    };
    if (
      policyCreatorState.thirdStepPayload.insurer.clientType === 'individual'
    ) {
      owner.contact = {
        ...owner.contact,
        firstName: policyCreatorState.thirdStepPayload.insurer.firstName,
        lastName: policyCreatorState.thirdStepPayload.insurer.lastName,
      };
    } else {
      owner.contact = {
        ...owner.contact,
        companyName: policyCreatorState.thirdStepPayload.insurer.companyName,
      };
    }
  } else {
    owner = {
      contact: {
        type:
          policyCreatorState.thirdStepPayload.insured.clientType ===
          'individual'
            ? 'person'
            : 'company',
        address: {
          addressLine1: policyCreatorState.thirdStepPayload.insured.address1,
          addressLine2:
            policyCreatorState.thirdStepPayload.insured.address2?.length === 0
              ? ' '
              : policyCreatorState.thirdStepPayload.insured.address2,
          street: policyCreatorState.thirdStepPayload.insured.address1,
          city: policyCreatorState.thirdStepPayload.insured.city,
          postCode: policyCreatorState.thirdStepPayload.insured.postalCode,
          countryCode: 'PL',
        },
        identificationNumber:
          policyCreatorState.thirdStepPayload.insured.idNumber,
      },
    };

    if (
      policyCreatorState.thirdStepPayload.insured.clientType === 'individual'
    ) {
      owner.contact = {
        ...owner.contact,
        firstName: policyCreatorState.thirdStepPayload.insured.firstName,
        lastName: policyCreatorState.thirdStepPayload.insured.lastName,
      };
    } else {
      owner.contact = {
        ...owner.contact,
        companyName: policyCreatorState.thirdStepPayload.insured.companyName,
      };
    }
  }

  const handlePremium = (): number => {
    switch (policyCreatorState.secondStepPayload.paymentType) {
      case 'PT_LS': {
        return offer.premiumDiscounted ?? offer.premiumSuggested;
      }
      case 'PT_LS_M': {
        return calculatePremiumForInstalments(
          offer.premiumDiscounted ?? offer.premiumSuggested,
          policyCreatorState.secondStepPayload.paymentPeriod,
        ).premiumCalculated;
      }
      case 'PT_A': {
        return calculatePremiumForAnnual(
          offer.premiumDiscounted ?? offer.premiumSuggested,
          policyCreatorState.secondStepPayload.policyPeriod,
        ).instalment;
      }
    }
  };

  const getPurchasedDate = () => {
    const isDateInRange = (date: string) => {
      return moment().diff(date) >= -60 && moment().diff(date) <= 120;
    };
    const now = new Date();
    const vehicleBuyDate = policyCreatorState.firstStepPayload.vehicleBuyDate;
    const acPolicyRenewalDate =
      policyCreatorState.firstStepPayload.acPolicyRenewalDate;
    let validDate;

    if (vehicleBuyDate && isDateInRange(vehicleBuyDate)) {
      validDate = vehicleBuyDate;
    }

    if (acPolicyRenewalDate && isDateInRange(acPolicyRenewalDate)) {
      validDate = acPolicyRenewalDate;
    }

    if (!validDate) {
      validDate = now;
    }

    return moment(validDate).format('YYYY-MM-DD');
  };

  const prepareNotes = (offer): string => {
    let notes: string = null;
    if (offer.discountLink) {
      notes = notes + `Link Afiliacyjny: ${offer.discountLink}\n`;
    }
    if (offer.discountCode) {
      notes = notes + `Kod Rabatowy: ${offer.discountCode}`;
    }

    return notes;
  };

  return {
    endorsementTypeCode: offer.endorsementTypeCode,
    // sellerNodeCode: 'iDefend-25297', // TODO: Handled by backend
    productCode: offer.productCode,
    saleInitiatedOn: moment(offer.saleInitiatedOn).format(),
    instalmentSplittingSchemeCode: checkForInstalments(policyCreatorState),
    vehicleSnapshot: {
      modelCode: offer.vehicleSnapshot.modelCode,
      usageTypeCode: offer.vehicleSnapshot.usageTypeCode,
      usageCode: offer.vehicleSnapshot.usageCode,
      purchasePriceInputType: offer.vehicleSnapshot.purchasePriceInputType,
      purchasePrice: offer.vehicleSnapshot.purchasePrice,
      purchasePriceNet: offer.vehicleSnapshot.purchasePriceNet,
      purchasePriceVatReclaimableCode:
        offer.vehicleSnapshot.purchasePriceVatReclaimableCode,
      specialVehicle: offer.vehicleSnapshot.specialVehicle,
      categoryCode: policyCreatorState.firstStepPayload.vehicleType.code,
      vin: policyCreatorState.fourthStepPayload.vin,
      firstRegisteredOn: moment(
        policyCreatorState.firstStepPayload.vehicleRegDate,
      ).format('YYYY-MM-DD'),
      purchasedOn: getPurchasedDate(),
      mileage: policyCreatorState.fourthStepPayload.millage,
      owners: [owner],
    },
    options: {
      ...offer.options,
    },
    signatureTypeCode: 'AUTHORIZED_BY_SMS', // TODO: consider getting signatureTypeCodes from api
    premium: handlePremium(),
    inceptionDate: moment(offer.inceptionDate).format('YYYY-MM-DD'),
    client: {
      policyHolder: {
        type: insured.clientType === 'individual' ? 'person' : 'company',
        address: {
          addressLine1: insured.address1,
          addressLine2: insured.address2?.length === 0 ? ' ' : insured.address2,
          street: insured.address1,
          city: insured.city,
          postCode: insured.postalCode,
          countryCode: 'PL',
        },
        firstName: insured.firstName,
        lastName: insured.lastName,
        companyName: insured.companyName,
        identificationNumber: insured.idNumber,
        phoneNumber: `${insured.phonePrefix + insured.phone}`,
        email: insured.email,
      },
      insured: {
        type: insured.clientType === 'individual' ? 'person' : 'company',
        address: {
          addressLine1: insured.address1,
          addressLine2: insured.address2?.length === 0 ? ' ' : insured.address2,
          street: insured.address1,
          city: insured.city,
          postCode: insured.postalCode,
          countryCode: 'PL',
        },
        firstName: insured.firstName,
        lastName: insured.lastName,
        companyName: insured.companyName,
        identificationNumber: insured.idNumber,
      },
    },
    note: prepareNotes(offer),
  };
}
