import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import {
  GapCalculator,
  GapCalculatorInterface,
} from '../../../interfaces/gap-calculator.interface';
import {
  GapSlider,
  GapSliderInterface,
} from '../../../interfaces/gap-slider.interface';
import { ApiService } from '../../services/api.service';
import { ApkService } from '../apk/apk.service';
import { positiveIntOnly } from '../apk/apk/validators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  gapCalculatorDto: GapCalculatorInterface;
  gapCalculatorFormGroup: UntypedFormGroup;
  gapSliderDto: GapSliderInterface;
  gapSliderFormGroup: UntypedFormGroup;
  years: any[];
  yearsDirect: any[];
  claimLimits: any[];
  selectedRegDate: number;
  vehiclePurchasePrice: number;
  selectedClaimLimit: number;
  calculatedPremium: number;
  insTermOptions: number[];
  insTermOptionsDirect: number[];
  typeOfPolicy = 'invoice';
  loadingCalculation = false;
  isBrowser = typeof window !== `undefined`;

  constructor(
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private apkService: ApkService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.claimLimits = [
      { value: 10000, name: '10 000 zł' },
      { value: 20000, name: '20 000 zł' },
      { value: 30000, name: '30 000 zł' },
      { value: 40000, name: '40 000 zł' },
    ];
    this.insTermOptions = [12, 24, 36, 48, 60];
    this.insTermOptionsDirect = [12, 24, 36];
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment) => {
      /*
        hack: for some reason without setTimout method the document cannot find element of id 'fragment'
        even if event listener 'DOMContentLoaded' is here, so the best solution is to add 'setTimout' and wait until
        element can be found
      */
      if (this.isBrowser) {
        if (!this.document.getElementById(fragment)) {
          setTimeout(() => {
            const element = this.document.getElementById(fragment);
            if (element) {
              element.scrollIntoView({ behavior: 'auto' });
            }
          }, 200);
        } else {
          const element = this.document.getElementById(fragment);
          if (element) {
            element.scrollIntoView({ behavior: 'auto' });
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.years = this.apkService.generateYearsSelectOptions(new Date(), 7);
    this.yearsDirect = this.apkService.generateYearsSelectOptions(
      new Date(),
      10,
    );
    this.initializeForm();
    this.initializeSliderForm();
  }

  setActualYearPlaceholder(): string {
    return moment().format('YYYY');
  }

  handleYearSelection(event: any): void {
    this.selectedRegDate = event.value.year;
  }

  handleLimitSelection(event: {
    originalEvent: any;
    value: { value: number; name: string };
  }): void {
    this.selectedClaimLimit = event.value.value;
    this.gapCalculatorFormGroup.get('claimLimit').setValue(event.value);
    // this.gapCalculatorFormGroup.controls.vehiclePurchasePrice.setValue(1);
  }

  onSubmit(): void {
    this.loadingCalculation = true;
    this.calculatedPremium = null;
    this.gapCalculatorDto = { ...this.gapCalculatorFormGroup.value };

    if (this.typeOfPolicy === 'direct') {
      this.gapCalculatorDto.claimLimit = this.selectedClaimLimit;
    } else {
      this.gapCalculatorDto.claimLimit = null;
    }

    this.gapCalculatorDto.vehicleRegDate = this.selectedRegDate;

    const calculationPayload = {
      productCode: null,
      saleInitiatedOn: moment().format('YYYY-MM-DD'), // '2024-07-16T07:26:57Z',
      endorsementTypeCode: 'NEW_BUSINESS',
      vehicleSnapshot: {
        categoryCode: 'PC',
        makeCode: '209',
        modelCode: '29',
        usageTypeCode: 'INDIVIDUAL',
        purchasePrice: this.gapCalculatorDto.vehiclePurchasePrice * 100,
        purchasePriceNet:
          Math.round(this.gapCalculatorDto.vehiclePurchasePrice / 1.23) * 100,
        purchasePriceInputType: 'WITH_VAT',
        purchasePriceVatReclaimableCode: 'NO',
        firstRegisteredOn: moment(this.gapCalculatorDto.vehicleRegDate).format(
          'YYYY-MM-DD',
        ),
        purchasedOn: moment().format('YYYY-MM-DD'),
        mileage: null,
        usageCode: 'STANDARD',
      },
      options: {
        CLAIM_LIMIT: null,
        TERM: `T_${this.gapCalculatorDto.insTerm}`,
        PAYMENT_TERM: 'PT_LS',
        PAYMENT_METHOD: 'PM_ONLINE',
      },
    };

    if (this.gapCalculatorDto.vehiclePurchasePrice >= 50000) {
      calculationPayload.options.CLAIM_LIMIT = 'CL_50000';

      if (this.typeOfPolicy === 'invoice') {
        // MAX
        calculationPayload.productCode = '5_DCGAP_MAX_GEN';
      }

      if (this.typeOfPolicy === 'casco') {
        // MAX AC
        calculationPayload.productCode = '5_DCGAP_MAC_GEN';
      }

      if (this.typeOfPolicy === 'direct') {
        // D2C
        calculationPayload.productCode = '5_DCGAP_DIR_GEN';
        calculationPayload.options.CLAIM_LIMIT = `CL_${this.gapCalculatorDto.claimLimit}`;
        calculationPayload.vehicleSnapshot.purchasePrice = 1;
      }
    } else {
      if (this.typeOfPolicy === 'direct') {
        // D2C
        calculationPayload.productCode = '5_DCGAP_DIR_GEN';
        calculationPayload.options.CLAIM_LIMIT = `CL_${this.gapCalculatorDto.claimLimit}`;
        calculationPayload.vehicleSnapshot.purchasePrice = 1;
      } else {
        this.typeOfPolicy = 'direct';
        calculationPayload.productCode = '5_DCGAP_DIR_GEN';
        calculationPayload.options.CLAIM_LIMIT = `CL_${this.gapCalculatorDto.claimLimit}`;
        calculationPayload.vehicleSnapshot.purchasePrice = 1;

        this.selectedClaimLimit =
          this.selectedClaimLimit || this.claimLimits[0].value;
        this.gapCalculatorFormGroup
          .get('claimLimit')
          .setValue(this.claimLimits[0]);
      }
    }

    this.apiService
      .getCalculation(calculationPayload)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.calculatedPremium = Math.round(response.premiumSuggested / 100);
          this.loadingCalculation = false;
        },
        () => {
          this.loadingCalculation = false;
          this.calculatedPremium = null;
        },
      );
  }

  selectTypeOfPolicy(type): void {
    this.typeOfPolicy = type;
    this.calculatedPremium = null;
  }

  initializeForm(): void {
    this.gapCalculatorDto = new GapCalculator();
    this.gapCalculatorFormGroup = this.fb.group({
      vehicleRegDate: [this.gapCalculatorDto.insTerm, [Validators.required]],
      insTerm: [this.gapCalculatorDto.insTerm, [Validators.required]],
      claimLimit: [this.gapCalculatorDto.claimLimit],
      vehiclePurchasePrice: [
        this.gapCalculatorDto.vehiclePurchasePrice,
        [positiveIntOnly(), RxwebValidators.maxNumber({ value: 600000 })],
      ],
    });
  }

  initializeSliderForm(): void {
    this.gapSliderDto = new GapSlider();
    this.gapSliderFormGroup = this.fb.group({
      vehiclePurchasePrice: [
        this.gapSliderDto.vehiclePurchasePrice,
        [positiveIntOnly(), RxwebValidators.maxNumber({ value: 600000 })],
      ],
      monthOfUse: [this.gapSliderDto.monthOfUse],
      monthsArray: [this.gapSliderDto.monthsArray],
      policyValue: [this.gapSliderDto.policyValue, [Validators.required]],
      gapValue: [this.gapSliderDto.gapValue, [Validators.required]],
    });
    this.gapSliderFormGroup.controls.monthOfUse.patchValue(36);
    this.gapSliderFormGroup.controls.monthsArray.patchValue([
      60 - this.gapSliderFormGroup.controls.monthOfUse.value,
      59,
    ]);
  }

  handleSliding(): void {
    this.gapSliderFormGroup.controls.monthOfUse.patchValue(
      60 - this.gapSliderFormGroup.controls.monthsArray.value[0],
    );
    if (this.gapSliderFormGroup.controls.vehiclePurchasePrice.value) {
      this.gapSliderFormGroup.controls.policyValue.patchValue(
        this.apkService.getVehiclePriceHomePage(
          this.gapSliderFormGroup.controls.monthOfUse.value,
          this.gapSliderFormGroup.controls.vehiclePurchasePrice.value,
        ),
      );
      this.calculateGapValue();
    }
  }

  calculateGapValue(): void {
    if (
      this.gapSliderFormGroup.controls.vehiclePurchasePrice.value -
        this.gapSliderFormGroup.controls.policyValue.value <
      150000
    ) {
      this.gapSliderFormGroup.controls.gapValue.patchValue(
        this.gapSliderFormGroup.controls.vehiclePurchasePrice.value -
          this.gapSliderFormGroup.controls.policyValue.value,
      );
    } else {
      this.gapSliderFormGroup.controls.gapValue.patchValue(150000);
    }
  }
}
