import { PolicyGroupPayload } from '../../../../interfaces/idefend/policy-groups-payload.interface';
import { QuestionManagementReplyPayload } from '../../../../interfaces/idefend/question-reply-payload.interface';
import { PaginationParams } from '../../../../interfaces/idefend/pagination-param.interface';
import { VehicleMake } from '../../../../interfaces/idefend/vehicle-make.interface';
import { SecondStepPayload } from '../../shared/interfaces/second-step-payload.interface';
import { ThirdStepPayload } from '../../shared/interfaces/third-step-payload.interface';
import { FourthStepPayload } from '../../shared/interfaces/fourth-step-payload.interface';
import { FirstStepPayload } from '../../shared/interfaces/first-step-payload.interface';
import { StepsValidators } from '../../../../interfaces/idefend/steps-validators.interface';

export class GetPortfolios {
  static readonly type = '[iDefend API] Get portfolios';

  constructor() {}
}

export class GetGroups {
  static readonly type = '[iDefend API] Get groups';

  constructor() {}
}

export class GetCurrency {
  static readonly type = '[iDefend API] Get currency';

  constructor(public id: number) {}
}

export class GetLocalizationSettings {
  static readonly type = '[iDefend API] Get localization settings';

  constructor(public id: number) {}
}

export class SetEmptySodanReplyHeads {
  static readonly type = '[iDefend API] Set Empty Sodan Reply Heads';

  constructor() {}
}

export class SetPolicyGroups {
  static readonly type = '[iDefend API] Set Policy Groups';

  constructor(public payload: PolicyGroupPayload) {}
}

export class PatchQuestionReply {
  static readonly type = '[iDefend API] Patch Question Reply';

  constructor(
    public payload: QuestionManagementReplyPayload,
    public query: { locale: string },
    public id: number,
  ) {}
}

export class GetVehicleCategories {
  static readonly type = '[iDefend API] Get Vehicle Categories';

  constructor(public query: PaginationParams) {}
}

export class GetVehicleUsages {
  static readonly type = '[iDefend API] Get Vehicle Usages';

  constructor(public query: PaginationParams) {}
}

export class GetVehicleMakes {
  static readonly type = '[iDefend API] Get Vehicle Makes';

  constructor() {}
}

export class GetVehicleModels {
  static readonly type = '[iDefend API] Get Vehicle Models';

  constructor() {}
}

export class FilterVehicleModelsByCategory {
  static readonly type = '[UI] Filter Vehicle Models By Category';

  constructor(
    public categoryId: number,
    public categoryCode: string,
  ) {}
}

export class FilterVehicleModelsByMake {
  static readonly type = '[UI] Filter Vehicle Models By Make';

  constructor(public selectedVehicleMake: VehicleMake) {}
}

export class PatchFirstStepFormValues {
  static readonly type = '[UI] Patch First Step Form Values';

  constructor(public firstStepPayload: FirstStepPayload) {}
}

export class PatchSecondStepFormValues {
  static readonly type = '[UI] Patch Second Step Form Values';

  constructor(public secondStepPayload: SecondStepPayload) {}
}

export class PatchThirdStepFormValues {
  static readonly type = '[UI] Patch Third Step Form Values';

  constructor(public thirdStepPayload: ThirdStepPayload) {}
}

export class PatchFourthStepFormValues {
  static readonly type = '[UI] Patch Fourth Step Form Values';

  constructor(public fourthStepPayload: FourthStepPayload) {}
}

export class PatchPaymentMethod {
  static readonly type = '[UI] Patch Payment Method';

  constructor(public paymentMethod: string) {}
}

export class PatchPaymentPeriod {
  static readonly type = '[UI] Patch Payment Period';

  constructor(public paymentPeriod: number) {}
}

export class SetActiveStep {
  static readonly type = '[UI] Set Active Step';

  constructor(public step: number) {}
}

export class ResetPolicyCreatorState {
  static readonly type = '[UI] Reset Policy Creator State';

  constructor() {}
}

export class SetStepsValidators {
  static readonly type = '[UI] Set validators';

  constructor(
    public stepValidator: {
      [key in 'first' | 'second' | 'third' | 'fourth']?: boolean;
    },
  ) {}
}
