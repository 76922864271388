import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-nav-bar-warranties',
  templateUrl: './nav-bar-warranties.component.html',
  styleUrls: ['./nav-bar-warranties.component.scss'],
})
export class NavBarWarrantiesComponent {
  baseUrl = environment.apkUrl;
  isMobileMenuOpen = false;

  getHref(path: string) {
    return this.baseUrl + `/${path}`;
  }

  toggleMobileMenu(): void {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }
}
