export interface DepreciationItem {
  month: number;
  beginningValue?: number;
  priceDecrease: number;
}

export class DepreciationItemC implements DepreciationItem {
  month: number;
  beginningValue?: number;
  priceDecrease: number;

  constructor(data) {
    this.month = data.month;
    this.beginningValue = 1 - data.priceDecrease;
    this.priceDecrease = data.priceDecrease;
  }
}

export const depreciationTable: DepreciationItem[] = [
  {
    month: 1,
    priceDecrease: 0,
  },
  {
    month: 2,
    priceDecrease: 0.0425,
  },
  {
    month: 3,
    priceDecrease: 0.07,
  },
  {
    month: 4,
    priceDecrease: 0.1,
  },
  {
    month: 5,
    priceDecrease: 0.13,
  },
  {
    month: 6,
    priceDecrease: 0.15,
  },
  {
    month: 7,
    priceDecrease: 0.1575,
  },
  {
    month: 8,
    priceDecrease: 0.165,
  },
  {
    month: 9,
    priceDecrease: 0.1725,
  },
  {
    month: 10,
    priceDecrease: 0.1825,
  },
  {
    month: 11,
    priceDecrease: 0.19,
  },
  {
    month: 12,
    priceDecrease: 0.2,
  },
  {
    month: 13,
    priceDecrease: 0.21,
  },
  {
    month: 14,
    priceDecrease: 0.2225,
  },
  {
    month: 15,
    priceDecrease: 0.235,
  },
  {
    month: 16,
    priceDecrease: 0.245,
  },
  {
    month: 17,
    priceDecrease: 0.2575,
  },
  {
    month: 18,
    priceDecrease: 0.27,
  },
  {
    month: 19,
    priceDecrease: 0.2825,
  },
  {
    month: 20,
    priceDecrease: 0.2925,
  },
  {
    month: 21,
    priceDecrease: 0.305,
  },
  {
    month: 22,
    priceDecrease: 0.3175,
  },
  {
    month: 23,
    priceDecrease: 0.3275,
  },
  {
    month: 24,
    priceDecrease: 0.34,
  },
  {
    month: 25,
    priceDecrease: 0.3525,
  },
  {
    month: 26,
    priceDecrease: 0.3625,
  },
  {
    month: 27,
    priceDecrease: 0.375,
  },
  {
    month: 28,
    priceDecrease: 0.385,
  },
  {
    month: 29,
    priceDecrease: 0.3975,
  },
  {
    month: 30,
    priceDecrease: 0.41,
  },
  {
    month: 31,
    priceDecrease: 0.42,
  },
  {
    month: 32,
    priceDecrease: 0.4275,
  },
  {
    month: 33,
    priceDecrease: 0.435,
  },
  {
    month: 34,
    priceDecrease: 0.4425,
  },
  {
    month: 35,
    priceDecrease: 0.45,
  },
  {
    month: 36,
    priceDecrease: 0.4575,
  },
  {
    month: 37,
    priceDecrease: 0.465,
  },
  {
    month: 38,
    priceDecrease: 0.4725,
  },
  {
    month: 39,
    priceDecrease: 0.4775,
  },
  {
    month: 40,
    priceDecrease: 0.485,
  },
  {
    month: 41,
    priceDecrease: 0.4925,
  },
  {
    month: 42,
    priceDecrease: 0.4975,
  },
  {
    month: 43,
    priceDecrease: 0.5025,
  },
  {
    month: 44,
    priceDecrease: 0.5075,
  },
  {
    month: 45,
    priceDecrease: 0.5125,
  },
  {
    month: 46,
    priceDecrease: 0.5175,
  },
  {
    month: 47,
    priceDecrease: 0.5225,
  },
  {
    month: 48,
    priceDecrease: 0.5275,
  },
  {
    month: 49,
    priceDecrease: 0.5325,
  },
  {
    month: 50,
    priceDecrease: 0.5375,
  },
  {
    month: 51,
    priceDecrease: 0.54,
  },
  {
    month: 52,
    priceDecrease: 0.545,
  },
  {
    month: 53,
    priceDecrease: 0.5475,
  },
  {
    month: 54,
    priceDecrease: 0.5525,
  },
  {
    month: 55,
    priceDecrease: 0.5575,
  },
  {
    month: 56,
    priceDecrease: 0.56,
  },
  {
    month: 57,
    priceDecrease: 0.5625,
  },
  {
    month: 58,
    priceDecrease: 0.565,
  },
  {
    month: 59,
    priceDecrease: 0.57,
  },
  {
    month: 60,
    priceDecrease: 0.58,
  },
];
