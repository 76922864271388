<nav id="top">
  <div class="wrapper">
    <ul id="desktop">
      <li id="d_1">
        <a
          id="menu_1"
          class="nav_field"
          [href]="getHref('autanowe.php')"
          >Auta<br />nowe</a
        >
      </li>
      <li id="d_2">
        <a
          id="menu_2"
          class="nav_field"
          [href]="getHref('autauzywane.php')"
          >Auta<br />używane</a
        >
      </li>
      <li id="d_3">
        <a
          [href]="getHref('badaniadiagnostyczne.php')"
          id="menu_3"
          class="nav_field"
          >Badania<br />diagnostyczne</a
        >
      </li>
      <li
        id="d_logo"
        class="logo">
        <a
          [href]="getHref('index.php')"
          class="nav_field"
          id="logotyp"
          ><img
            src="assets/images/gwarancje-online-logo.svg"
            alt="Gwarancje — online  — logo"
        /></a>
      </li>
      <li id="d_4">
        <a
          [href]="getHref('motocykle.php')"
          class="nav_field"
          id="menu_4"
          >Motocykle</a
        >
      </li>
      <li id="d_5">
        <a
          class="nav_field"
          id="menu_5"
          >Moto<br />poradnik</a
        >
        <ul id="submenu">
          <li>
            <a
              class="subnav"
              [href]="getHref('szkoda.php')"
              >Jak zgłosić szkodę</a
            >
          </li>
          <li>
            <a
              class="subnav"
              [href]="getHref('wylaczenia.php')"
              >Czego nie obejmuje przedłużona gwarancja?</a
            >
          </li>
          <li>
            <a
              class="subnav"
              [href]="getHref('faq.php')"
              >FAQ - Często zadawane pytania</a
            >
          </li>
          <li>
            <a
              class="subnav"
              [href]="getHref('kluczyki.php')"
              >Ubezpieczenie kluczyków</a
            >
          </li>
          <li>
            <a
              class="subnav"
              href="http://blog.gwarancjeonline.pl"
              target="_blank"
              >Blog</a
            >
          </li>
        </ul>
      </li>
      <li id="kup">
        <a
          id="menu_6"
          class="nav_field"
          [href]="getHref('kup.php')">
          <div class="kup_bg">
            Kup&nbsp;<img
              src="assets/images/koszyk.svg"
              alt="" />
          </div>
        </a>
      </li>
      <li id="blog">
        <a
          id="menu_7a"
          class="nav_field nav_field--img-fixed"
          href="http://blog.gwarancjeonline.pl"
          target="_blank">
          <!--<img
            id="menu_7a_button"
            src="assets/images/blog.svg"
            alt="mobile-menu" />-->
          <svg-icon
            src="assets/images/blog.svg"
            [svgClass]="'nav_field__img'"
            [svgStyle]="{ 'width.px': 74 }"></svg-icon>
        </a>
      </li>
      <li id="gap">
        <a
          class="nav_field nav_field--img-fixed"
          id="menu_8"
          href="http://gaponline.pl"
          target="_blank">
          <!--<img
            id="menu_8_button"
            src="assets/images/gap.svg"
            alt="mobile-menu" />-->
          <svg-icon
            src="assets/images/gap.svg"
            [svgStyle]="{ 'width.px': 74 }"></svg-icon>
        </a>
      </li>
      <li id="menu_mob">
        <a
          class="nav_field"
          (click)="toggleMobileMenu()"
          ><img
            id="mobile_nav_button"
            src="assets/images/mobile-on.svg"
            alt="mobile-menu"
        /></a>
        <ul
          id="mobile_nav"
          class="mobile-nav"
          [ngClass]="{ 'mobile-nav--open': isMobileMenuOpen }">
          <li id="m_1">
            <a
              id="mobile_1"
              class="mob_field"
              [href]="getHref('autanowe.php')"
              >Auta nowe</a
            >
          </li>
          <li id="m_2">
            <a
              id="mobile_2"
              class="mob_field"
              [href]="getHref('autauzywane.php')"
              >Auta używane</a
            >
          </li>
          <li id="m_3">
            <a
              id="mobile_3"
              class="mob_field"
              [href]="getHref('badaniadiagnostyczne.php')"
              >Badania diagnostyczne</a
            >
          </li>
          <li id="m_4">
            <a
              id="mobile_4"
              class="mob_field"
              [href]="getHref('motocykle.php')"
              >Motocykle</a
            >
          </li>
          <li id="m_5">
            <span
              id="mobile_5"
              class="mob_field"
              >Moto poradnik</span
            >
            <ul id="mobile_submenu">
              <li>
                <a
                  class="mob_subnav"
                  [href]="getHref('szkoda.php')"
                  >Jak zgłosić szkodę</a
                >
              </li>
              <li>
                <a
                  class="mob_subnav"
                  [href]="getHref('wylaczenia.php')"
                  >Czego nie obejmuje przedłużona gwarancja?</a
                >
              </li>
              <li>
                <a
                  class="mob_subnav"
                  [href]="getHref('faq.php')"
                  >FAQ - Często zadawane pytania</a
                >
              </li>
              <li>
                <a
                  class="mob_subnav"
                  [href]="getHref('kluczyki.php')"
                  >Ubezpieczenie kluczyków</a
                >
              </li>
              <li>
                <a
                  class="mob_subnav"
                  href="http://blog.gaponline.pl/"
                  >Blog</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>
